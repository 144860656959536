<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-container v-show="pageLoaded === true"
                 class="py-3 px-sm-5 mb-16">
      <div style="position: relative">
        <div style="position:relative">
          <!--  region Cover -->
          <v-img :alt="business.slug"
                 :gradient="business.cover.path !== '' ? 'to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)' : ''"
                 :src="business.cover.path !== '' ? business.cover.path : require('@/assets/cover.jpg')"
                 class="rounded-lg align-start mb-3"
                 height="200px">
          </v-img>
          <!-- endregion -->
          <!-- region Logo -->
          <v-avatar color="#fff"
                    size="120"
                    style="position: absolute; left: 50%; bottom: -20px; margin-left: -60px; border: 3px solid white; border: 3px solid white !important;">
            <img v-if="business.logo.path !== ''"
                 :alt="business.slug"
                 :lazy-src="require('@/assets/loading.svg')"
                 :src="business.logo.path"/>
            <span v-else
                  class="text-h1 font-weight-medium"
                  style="color: #00999a">
              {{ getFirstCharacter(business.name) }}
            </span>
          </v-avatar>
          <!-- endregion -->
        </div>
        <!--region Name and rating-->
        <div class="text-center py-6">
          <h1 v-html="business.name"></h1>
          <v-rating v-model="business.rating"
                    background-color="orange lighten-3"
                    color="orange"
                    dense>
          </v-rating>
          <!--<AppNotificationButton :business="business"></AppNotificationButton>-->
        </div>
        <!--endregion-->

        <!--region Details-->
        <div class="text-center">
          <p class="subtitle-1 pl-1 px-md-15" v-html="business.description"></p>
          <h4 class="pl-1 mb-4">Ubicado en {{ business.address + ', ' + business.city + ', ' + business.state }}</h4>

          <!-- Social Network -->
          <div class="mb-10">
            <h4 class="text-subtitle-2 pl-1" style="margin-bottom: 5px;">
              Comparte con tus amigos en:
            </h4>
            <!--Facebook-->
            <v-btn :href="'https://www.facebook.com/sharer/sharer.php?u=https://api.seoferta.net/share/'+business.slug"
                   class="mx-1"
                   color="#3b5998" dark elevation="none" link rounded target="_blank" x-small>
              <v-icon left small>mdi-facebook</v-icon>
              Facebook
            </v-btn>
            <!--Twitter-->
            <v-btn :href="'whatsapp://send?text=' + 'https://api.seoferta.net/share/'+business.slug" class="mx-1"
                   color="#00bb2d"
                   dark
                   data-action="share/whatsapp/share"
                   elevation="none"
                   link
                   rounded
                   x-small>
              <v-icon left small>mdi-whatsapp</v-icon>
              whatsapp
            </v-btn>
            <!--Telegram-->
            <v-btn :href="'tg://msg_url?url=' + 'https://api.seoferta.net/share/'+business.slug"
                   class="mx-1"
                   color="#0088cc"
                   dark
                   elevation="none"
                   link
                   rounded
                   x-small>
              <svg aria-hidden="true" height="16" preserveAspectRatio="xMidYMid meet" role="img"
                   style="margin-right: 8px"
                   viewBox="0 0 24 24"
                   width="16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path
                    d="M20.665 3.717l-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42l10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001l-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15l4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                    fill="white"/>
              </svg>
              telegram
            </v-btn>
          </div>
        </div>
        <!--endregion-->

        <!--region List categories-->
        <v-divider></v-divider>
        <vue-position-sticky v-if="business.categories.length > 0">
          <v-sheet class="py-2">
            <v-chip-group id="menu"
                          :show-arrows="true"
                          color="#008081">
              <v-chip v-for="cat in business.categories"
                      v-if="cat.products.length > 0" :key="cat.id"
                      :href="'#'+cat.name"
                      class="chip"
                      link>
                <h3 class="text-subtitle-2">{{ cat.name }}</h3>
              </v-chip>
            </v-chip-group>
          </v-sheet>
          <v-divider class="mb-3"></v-divider>
        </vue-position-sticky>
        <!--endregion-->

        <!-- List Products -->
        <v-row class="mb-5">
          <v-col cols="12" :md="business.allowOrders === true ? 8 : 12">
            <!-- region No show products -->
            <div v-if="containProducts === false"
                 :class="business.allowOrders === true ? 'text-center mt-8' : 'text-center mt-12' ">
              <h3>Muestra aquí tus productos y servicios</h3>
              <p>¡Arriba! No demores, queremos saber que traes entre manos ;)</p>
              <img :src="require('@/assets/waiting-product.svg')" style="max-height: 600px"/>
              <v-btn v-if="isLogged === true"
                     :to="{ name: 'admin_business_edit_products', params: { slug: $route.params.slug } }"
                     :class="business.allowOrders === true ? 'text-none text-body-1 font-weight-medium mb-8' : 'text-none text-body-1 font-weight-medium mb-12'"
                     color="secondary"
                     depressed
                     large
                     link
                     rounded>
                Adicionar producto
              </v-btn>
            </div>
            <!-- endregion -->

            <!--region Show product -->
            <div v-if="containProducts === true" class="mr-sm-3">
              <!-- View List -->
              <v-list v-if="business.viewMode === 'list'"
                      v-for="cat in business.categories" :key="cat.id"
                      class="py-0"
                      two-line>
                <v-subheader v-if="cat.products.length > 0"
                             :id="cat.name"
                             class="text-button title-scroll-margin" style="font-size: 12px!important;"
                             v-html="cat.name + ' (' + cat.products.length + ')'">
                </v-subheader>
                <v-list-item v-for="prod in cat.products"
                             :key="prod.id"
                             class="mb-2 rounded-lg"
                             style="background: #f5f5f7"
                             @click.stop="selectProductForView(prod, true, $event)">
                  <v-list-item-avatar color="#fff" size="65">
                    <v-img v-if="prod.images.length > 0 && prod.images[0].thumb && prod.images[0].thumb !== ''"
                           :lazy-src="require('../assets/loading-buffering.svg')" :src="prod.images[0].thumb"></v-img>
                    <span v-else
                          class="text-h4 font-weight-medium"
                          style="color: #00999a">
                    {{ getFirstCharacter(prod.name) }}
                  </span>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <h4 class="font-weight-medium" v-html="prod.name"></h4>
                    <v-list-item-subtitle v-html="prod.description"></v-list-item-subtitle>
                    <v-list-item-subtitle class="pt-1">
                      <h4 class="text-subtitle-2"
                          style="color: black">
                        ${{ prod.price }} <span class="font-weight-regular">{{ prod.currency }}</span>
                      </h4>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="business.allowOrders === true">
                    <v-btn color="primary"
                           dark
                           elevation="none"
                           fab
                           small
                           @click="setProductInCar(prod, +1, $event)">
                      <v-icon size="30px">mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <!-- View Card -->
              <v-list v-if="business.viewMode === 'card'"
                      v-for="cat in business.categories"
                      :key="cat.id"
                      class="py-0"
                      two-line>
                <v-subheader v-if="cat.products.length > 0"
                             :id="cat.name"
                             class="text-button title-scroll-margin" style="font-size: 12px!important;"
                             v-html="cat.name + ' (' + cat.products.length + ')'">
                </v-subheader>

                <v-row>
                  <v-col v-for="prod in cat.products"
                         :key="prod.id"
                         cols="12"
                         :lg="business.allowOrders === true ? 4 : 3"
                         md="4"
                         sm="6">
                    <v-card @click.stop="selectProductForView(prod, true, $event)" style="cursor: pointer">
                      <!-- add img-none -->
                      <v-img :src="(prod.images.length > 0 && prod.images[0].path !== '') ? prod.images[0].path : require('../assets/img-none.svg')"
                             class="align-end"
                             :lazy-src="require('../assets/loading-buffering.svg')"
                             height="150px">
                        <div class="pa-2">
                          <v-chip small outlined style="background-color: #de8e55 !important; color: white">
                            <span class="font-weight-bold pr-1">${{ prod.price }}</span>{{ prod.currency }}
                          </v-chip>
                        </div>
                      </v-img>
                      <div style="position: relative;">
                        <v-btn v-if="business.allowOrders === true" color="primary"
                               elevation="1"
                               @click="setProductInCar(prod, +1, $event)"
                               absolute
                               fab
                               small
                               right
                               top>
                          <v-icon color="white">mdi-plus</v-icon>
                        </v-btn>
                        <div class="pl-2 pb-2 pt-4 pr-10">
                          <h4 class="font-weight-medium" style="font-size: 14px">
                            {{prod.name}}
                          </h4>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-list>

              <!-- Dialog Details -->
              <v-dialog v-model="productDetailsDialog"
                        :fullscreen="fullscreen"
                        persistent
                        style="z-index: 1000"
                        width="500">
                <v-card class="pb-2 rounded-lg" style="position: relative">
                  <v-toolbar color="white" elevation="1" style="z-index: 99">
                    <v-avatar color="#f5f5f7">
                      <v-img v-if="business.logo && business.logo.thumb && business.logo.thumb !== ''"
                             :lazy-src="require('../assets/loading.svg')" :src="business.logo.thumb"></v-img>
                      <span v-else class="text-h4 font-weight-medium" style="color: #00999a">
                      {{ getFirstCharacter(business.name) }}
                    </span>
                    </v-avatar>
                    <v-toolbar-title
                        class="pb-1 pl-3"
                        style="line-height: 1.2; font-size: 18px">
                      <small style="font-size: 13px">Ofertado por:</small>
                      <br/>
                      {{ business.name }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed
                           icon
                           rounded
                           @click="closeDialog">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <!-- Slide -->
                  <v-carousel v-if="selectedProduct.images.length > 0"
                              :next-icon="selectedProduct.images.length>1?'mdi-chevron-right':false"
                              :prev-icon="selectedProduct.images.length>1?'mdi-chevron-left':false"
                              height="250"
                              hide-delimiters>
                    <v-carousel-item v-for="image in selectedProduct.images"
                                     :key="image.id">
                      <v-img :lazy-src="require('../assets/loading-buffering.svg')"
                             :src="image.path"
                             aspect-ratio="1.5"
                             contain
                             height="250">
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>

                  <v-card-title class="pb-4">{{ selectedProduct.name }}</v-card-title>
                  <v-card-subtitle class="mb-16" style="white-space: break-spaces">{{
                      selectedProduct.description
                    }}
                  </v-card-subtitle>
                  <v-footer absolute class="px-6 py-3" flat>
                    <h3 class="font-weight-bold" style="color: #00999a" translate="no">
                      ${{ selectedProduct.price + ' ' }}<span
                        class="font-weight-regular">{{ selectedProduct.currency }}</span></h3>
                    <v-spacer></v-spacer>
                    <v-btn v-if="business.allowOrders === true"
                           class="text-none"
                           color="primary"
                           dark
                           elevation="0"
                           rounded
                           @click.stop="addProductFromModal(selectedProduct, $event)">
                      <v-icon small>mdi-plus</v-icon>
                      Adicionar
                    </v-btn>
                  </v-footer>
                </v-card>
              </v-dialog>
            </div>
            <!-- endregion -->
          </v-col>

          <v-col v-if="business.allowOrders === true" id="card-container" class="title-scroll-margin" cols="12" md="4">
            <!-- region Shop car -->
            <v-card class="py-5 mt-sm-5 rounded-lg">
              <h2 class="text-center">Carrito de productos</h2>
              <!-- EMPTY CAR -->
              <v-card v-if="myCar.products.cup.length === 0 && myCar.products.usd.length === 0"
                      class="my-5"
                      flat
                      tile>
                <v-card-text class="text-center">
                  <h3>Tu carro está vacío</h3>
                  <p>Cuando añada un producto, aparecerá aquí</p>
                  <v-img :src="require('../assets/carrito-vacio.svg')"
                         contain
                         max-height="100">
                  </v-img>
                </v-card-text>
              </v-card>
              <div v-if="myCar.products.cup.length > 0 || myCar.products.usd.length > 0">
                <p class="mb-2 text-center text-subtitle-2 font-weight-regular">Aquí puedes eliminar o adicionar más
                  productos</p>
                <div v-if="myCar.products.cup.length > 0">
                  <!--Group CUP -->
                  <v-list dense>
                    <v-list-item v-for="(prod, i) in myCar.products.cup" :key="i">
                      <v-btn color="greyPrimary"
                             depressed
                             fab
                             x-small
                             @click="setProductInCar(prod.item, -1, $event)">
                        <v-icon style="font-size: 20px">mdi-minus</v-icon>
                      </v-btn>
                      <v-list-item-content class="px-2">
                        <h4 class="subtitle-2 font-weight-medium">
                          {{ prod.item.name }}
                        </h4>
                        <v-list-item-subtitle>{{ prod.item.price }} CUP
                          <v-chip color="error" small>x {{ prod.count }}</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn class="mx-2"
                               color="greyPrimary"
                               depressed fab x-small
                               @click="setProductInCar(prod.item, +1, $event)">
                          <v-icon style="font-size: 20px">mdi-plus</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider class="mx-3"></v-divider>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h3 class="font-weight-medium" style="font-size: 15px">TOTAL</h3>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action-text class="pr-2">
                        <h3 class="font-weight-medium" style="color: #008081; font-size: 15px">$ {{ priceCUP }} CUP</h3>
                      </v-list-item-action-text>
                    </v-list-item>
                  </v-list>
                  <v-divider class="mx-3"></v-divider>
                </div>
                <div v-if="myCar.products.usd.length > 0">
                  <!--Group USD -->
                  <v-list dense>
                    <v-list-item v-for="(prod, i) in myCar.products.usd" :key="i">
                      <v-btn color="greyPrimary"
                             depressed
                             fab
                             x-small
                             @click="setProductInCar(prod.item, -1, $event)">
                        <v-icon style="font-size: 20px">mdi-minus</v-icon>
                      </v-btn>
                      <v-list-item-content class="px-2">
                        <h4 class="subtitle-2 font-weight-medium">
                          {{ prod.item.name }}
                        </h4>
                        <v-list-item-subtitle>{{ prod.item.price }} USD
                          <v-chip color="error" small>x {{ prod.count }}</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn class="mx-2" color="greyPrimary" depressed fab x-small
                               @click="setProductInCar(prod.item, +1, $event)">
                          <v-icon style="font-size: 20px">mdi-plus</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider class="mx-3"></v-divider>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h3 class="font-weight-medium" style="; font-size: 15px">TOTAL</h3>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action-text class="pr-2">
                        <h3 class="font-weight-medium" style="color: #008081; font-size: 15px">$ {{ priceUSD }} USD</h3>
                      </v-list-item-action-text>
                    </v-list-item>
                  </v-list>
                  <v-divider class="mx-3"></v-divider>
                </div>
              </div>
              <!--ORDER -->
              <validation-observer
                  ref="formOrderObserver"
                  v-slot="{invalid}">
                <v-card class="my-5 text-center" color="#f5f5f7" flat tile>
                  <h2 class="pt-8">¿Cómo recibir el pedido?</h2>
                  <p class="mb-2 text-center text-subtitle-2 font-weight-regular">
                    Indica cómo o adonde deseas recibir el pedido </p>

                  <v-card-text>
                    <v-chip-group
                        v-model="activeDelivery"
                        class="my-chip-center mb-5 font-weight-medium chip text-center"
                        color="primaryDark"
                        mandatory
                        style="display: block">
                      <v-chip v-if="business.deliveryInfo.hasHomeDelivery"
                              filter
                              outlined
                              value="home">
                        <h3 class="text-subtitle-2">Al domicilio</h3>
                      </v-chip>
                      <v-chip v-if="business.deliveryInfo.hasBusinessDelivery"
                              filter
                              outlined
                              value="business">
                        <h3 class="text-subtitle-2">En el local</h3>
                      </v-chip>
                    </v-chip-group>

                    <validation-provider v-slot="{errors}"
                                         rules="required">
                      <v-text-field v-model="clientData.name"
                                    :error-messages="errors"
                                    color="#00999a"
                                    label="Nombre"
                                    outlined>
                      </v-text-field>
                    </validation-provider>

                    <validation-provider v-if="business.deliveryInfo.hasHomeDelivery && activeDelivery === 'home'"
                                         v-slot="{errors}"
                                         rules="required">
                      <v-text-field v-if="business.deliveryInfo.hasHomeDelivery && activeDelivery === 'home'"
                                    v-model="clientData.address"
                                    :error-messages="errors"
                                    color="#00999a"
                                    label="Dirección"
                                    outlined>
                      </v-text-field>
                    </validation-provider>
                    <v-textarea v-model="clientData.note"
                                auto-grow
                                class="mb-5"
                                color="#00999a"
                                hide-details="none"
                                label="Añade una nota al pedido"
                                outlined
                                rows="3">
                    </v-textarea>
                    <validation-provider v-if="business.deliveryInfo.hasHomeDelivery && activeDelivery === 'home'">
                      <p class="mb-1" style="font-size: 16px">Precio del servicio al domicilio</p>
                      <div class="mb-4">
                        <v-chip v-for="(item, index) in business.deliveryInfo.homeDeliveryZones"
                                :key="index"
                                :ripple="false"
                                class="ma-2"
                                label
                                outlined>
                          <span class="font-weight-bold pr-1">{{ item.zone }}</span>
                          {{
                            item.value + " " + item.currency
                          }}
                        </v-chip>
                      </div>
                    </validation-provider>
                    <p v-if="business.deliveryInfo.hasHomeDelivery && activeDelivery === 'home'"
                       class="text-subtitle-1" style="color: black">
                      {{ business.deliveryInfo.homeDeliveryNote }}
                    </p>
                    <p v-if="business.deliveryInfo.hasBusinessDelivery && activeDelivery === 'business'"
                       class="text-subtitle-1" style="color: black">
                      {{ business.deliveryInfo.businessDeliveryNote }}
                    </p>
                  </v-card-text>
                </v-card>

              </validation-observer>

              <!-- Button whatsapp -->
              <v-card-actions>
                <v-btn :disabled="totalProductInCard === 0"
                       :loading="creatingOrderLoading"
                       block
                       color="#00bb2d"
                       large
                       rounded
                       style="color: white"
                       @click="createOrder()">
                  <v-icon left>mdi-whatsapp</v-icon>
                  Enviar a Whatsapp
                </v-btn>
              </v-card-actions>
              <div v-if="!validCarForm" style="padding: 0 20px;">
                <v-alert dense
                         outlined
                         style="font-size: 14px"
                         type="error">
                  No se ha podido procesar el envío debido a que faltan datos por llenar.
                </v-alert>
              </div>
            </v-card>
            <!--endregion-->
          </v-col>
        </v-row>

        <v-fab-transition v-if="business.allowOrders === true">
          <v-btn bottom
                 color="secondary"
                 fab
                 fixed
                 href="#card-container"
                 link
                 right>
            <v-icon>mdi-cart</v-icon>
            <v-badge v-if="totalProductInCard>0"
                     :content="totalProductInCard"
                     bordered
                     color="error"
                     style="top: -15px;"
                     v-bind:class="{ zoomInZoomOut : runAnimate }">
            </v-badge>
          </v-btn>
        </v-fab-transition>

        <!-- region Install -->
        <AppInstallationButton :business="business"></AppInstallationButton>
        <!-- endregion -->

      </div>
      <!-- region Footer -->
      <v-footer absolute outlined padless>
        <v-container>
          <v-card class="d-flex flex-wrap align-center" color="greyPrimary" flat tile>
            <v-btn :to="{name: 'home'}" class="text-none px-2" plain target="_blank">
              SeOferta
            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn v-if="isLogged === true"
                   :to="{ name: 'admin_business_edit_business', params: { slug: $route.params.slug } }"
                   class="text-none px-2" plain target="_blank">Administrar
            </v-btn>
            <v-btn :to="{name: 'business'}" class="text-none px-2" plain target="_blank">
              ¿Qué estás buscando?
            </v-btn>
          </v-card>
        </v-container>

      </v-footer>
      <!--endregion-->

    </v-container>
    <LoadingBusiness v-show="pageLoaded === false"></LoadingBusiness>
    <audio id="cardSound"
           :src="require('../assets/sounds/coin.mp3')"
           controls
           preload="none"
           style="display: none"
           vol>
    </audio>
  </div>
</template>

<script>
import services from "../services";
import {required} from 'vee-validate/dist/rules'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import dialogMixin from "vue-dialog-mixin"
import {getFirstChar} from "../utils/data";

const LoadingBusiness = () => import(/* webpackPrefetch: true */ "../components/LoadingBusiness");
const AppInstallationButton = () => import(/* webpackPrefetch: true */ "../components/AppInstallationButton");
const AppNotificationButton = () => import(/* webpackPrefetch: true */ "../components/AppNotificationButton");
const LoadingImage = () => import(/* webpackPrefetch: true */ "../components/LoadingImage");

extend('required', {
  ...required,
  message: 'El campo no puede estar vacio.',
})

export default {
  overlay: false,
  name: "Business",
  mixins: [dialogMixin],
  components: {
    LoadingImage,
    ValidationProvider,
    ValidationObserver,
    LoadingBusiness,
    AppInstallationButton,
    AppNotificationButton
  },
  created() {
    let manifestUrl = services.business.getManifestUrl(this.$route.params.slug);
    // manifestUrl = "http://localhost:8080/manifest.json";// TODO: remove for receive real json
    document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestUrl);
  },
  data: () => ({
    tab: null,
    activeDelivery: undefined,
    business: {
      id: '',
      url: '',
      name: '',
      slug: '',
      logo: '',
      cover: '',
      description: '',
      rating: 5,
      phoneNumber: '',
      viewMode: 'list',
      allowOrders: true,
      categories: [],
      deliveryInfo: {
        hasHomeDelivery: true,
        homeDeliveryPrice: 0,
        homeDeliveryNote: '',
        homeDeliveryZones: [],

        hasBusinessDelivery: true,
        businessDeliveryAddress: '',
        businessDeliveryNote: ''
      },
    },
    containProducts: false,
    productDetailsDialog: false,
    selectedProduct: {
      images: []
    },
    selectedProductInCar: {},
    runAnimate: true,
    myCar: {
      orderNumber: '',
      products: {
        cup: [],
        usd: []
      },
    },
    clientData: {
      name: '',
      address: '',
      note: ''
    },
    creatingOrderLoading: false,
    validCarForm: true,
    pageLoaded: false,
  }),
  metaInfo() {
    return {
      title: this.business.name + " - SeOferta.NET",
    }
  },
  mounted: function () {
    const slug = this.$route.params.slug;
    if (!slug) {
      this.$router.push({name: 'error'});
    } else {
      services
          .business
          .getFullInfo(slug)
          .then((result) => {
            if (!result.data.id) {
              this.$router.push({name: 'errorInternal'});
            } else {

              if (slug.toLowerCase() !== result.data.slug.toLowerCase()) {
                this.$router.push({name: 'businessDetails', params: {slug: result.data.slug}});
              }

              this.business = {
                id: result.data.id,
                url: 'https://seoferta.net' + this.$route.path,
                slug: result.data.slug,
                name: result.data.name,
                logo: result.data.logo,
                cover: result.data.cover,
                description: result.data.description,
                rating: result.data.rating,
                phoneNumber: result.data.phone_number,
                address: result.data.address,
                city: result.data.city,
                state: result.data.state,

                viewMode: result.data.view_mode,
                allowOrders: result.data.allow_orders,

                categories: result.data.categories || [],

                deliveryInfo: {
                  hasHomeDelivery: result.data.home_delivery,
                  homeDeliveryPrice: result.data.home_delivery_price,
                  homeDeliveryNote: result.data.home_delivery_note,
                  homeDeliveryZones: result.data.home_delivery_zones,


                  hasBusinessDelivery: result.data.delivery_in_business,
                  businessDeliveryAddress: result.data.address,
                  businessDeliveryNote: result.data.delivery_in_business_note
                }
              };

              for (let i = 0; i < this.business.categories.length; i++) {
                let catProducts = this.business.categories[i].products || [];
                if (catProducts.length > 0) {
                  this.containProducts = true;
                  break;
                }
              }

              this.myCar = {
                orderNumber: result.data.order_number,
                products: {
                  cup: [],
                  usd: []
                },
              };

              if (this.business.deliveryInfo.hasHomeDelivery) {
                this.activeDelivery = "home";
              } else {
                this.activeDelivery = "business";
              }

              this.loadClientInfo();
              this.reportBusinessVisitor();

              this.pageLoaded = true;
            }
          })
          .catch(() => {
            this.$router.push({name: 'error'});
          });
    }
  },
  watch: {
    '$route.hash'(newHash, oldHash) {
      if (newHash === '#showDetails') {
        this.productDetailsDialog = true;
      } else if (oldHash === '#showDetails') {
        this.productDetailsDialog = false;
      }
    },
  },
  methods: {

    /**
     * Add the product to the car.
     */
    addProductFromModal: function (product, event) {
      this.setProductInCar(product, +1, event)
      this.productDetailsDialog = false
    },

    /**
     *
     */
    closeDialog: function () {
      //this.productDetailsDialog = false;
      this.$router.back();
    },

    /**
     * Add the product to the car.
     */
    setProductInCar: function (product, quantity, event) {
      this.playCardSound()

      switch (product.currency.toUpperCase()) {
        case "CUP": {
          let prodIndex = this.myCar.products.cup.findIndex(p => p.item.id === product.id);
          if (prodIndex < 0) { // not exist, then add
            this.myCar.products.cup.push({
              count: 1,
              item: product
            })
          } else { //exist, then update or remove
            // update quantity
            this.myCar.products.cup[prodIndex].count += quantity;

            //remove if needed
            if (this.myCar.products.cup[prodIndex].count <= 0) {
              this.myCar.products.cup.splice(prodIndex, 1);
            }
          }
          break;
        }
        case "USD": {
          let prodIndex = this.myCar.products.usd.findIndex(p => p.item.id === product.id);
          if (prodIndex < 0) { // not exist, then add
            this.myCar.products.usd.push({
              count: 1,
              item: product
            })
          } else { //exist, then update or remove
            // update quantity
            this.myCar.products.usd[prodIndex].count += quantity;

            //remove if needed
            if (this.myCar.products.usd[prodIndex].count <= 0) {
              this.myCar.products.usd.splice(prodIndex, 1);
            }
          }
          break;
        }
      }

      this.runAnimate = false
      setTimeout(() => {
        this.runAnimate = true
      }, 100);

      event.stopPropagation();
    },

    /**
     *
     */
    playCardSound() {
      const audio = document.getElementById('cardSound')
      audio.volume = 0.5;
      audio.pause();
      audio.currentTime = 0;
      audio.play()
    },

    /**
     * Select a product for view details.
     */
    selectProductForView: function (product, show, event) {
      this.selectedProduct = product;
      this.$router.push('#showDetails');
      this.productDetailsDialog = true;
      this.reportProductVisualization(this.selectedProduct.id);
    },

    /**
     * Launch whatsapp.
     */
    createOrder: async function () {

      const isValid = await this.$refs.formOrderObserver.validate();
      this.validCarForm = isValid;
      if (!isValid)
        return;

      this.creatingOrderLoading = true;

      let allProducts = [];
      this.myCar.products.cup.forEach((p) => {
        allProducts.push({
          product_id: p.item.id,
          amount: p.count,
        })
      });
      this.myCar.products.usd.forEach((p) => {
        allProducts.push({
          product_id: p.item.id,
          amount: p.count,
        })
      });

      const orderData = {
        orderNumber: this.myCar.orderNumber,
        products: allProducts,
        home_delivery: this.activeDelivery === "home"
      };

      const userData = {
        name: this.clientData.name,
        address: this.clientData.address,
        phone: '',
        note: this.clientData.note
      };

      try {
        const result = await services.orders.createOrder(this.business.id, orderData, userData);

        let shareText = "";
        if (this.activeDelivery === "home") {
          shareText += 'Hola, me gustaría comprar los siguientes productos y que me los traigan a la siguiente dirección:\n';
          shareText += this.clientData.address + "\n\n";
        } else {
          shareText += 'Hola, me gustaría adquirir los siguientes productos:\n\n';
        }

        this.myCar.products.cup.forEach((p) => {
          shareText += p.item.name + " (" + p.item.price + " " + p.item.currency + ") * " + p.count + "\n";
        });
        this.myCar.products.usd.forEach((p) => {
          shareText += p.item.name + " (" + p.item.price + " " + p.item.currency + ") * " + p.count + "\n";
        });
        shareText += "\n";

        shareText += 'Total:';
        if (this.priceCUP > 0) {
          shareText += " " + this.priceCUP + ' CUP ';
        }
        if (this.priceUSD > 0) {
          shareText += this.priceCUP > 0 ? " +" : "";
          shareText += " " + this.priceUSD + " USD";
        }

        if (this.clientData.note.trim() !== "") {
          shareText += "\n\n";
          shareText += 'Nota: ' + this.clientData.note;
        }

        shareText += "\n\n";
        shareText += this.business.url + "/invoice/" + result.data.order_number;

        if (encodeURI) {
          shareText = encodeURI(shareText)
        } else {
          shareText = escape(shareText)
        }

        shareText = this.replaceAll(shareText, '#', '%23');
        shareText = this.replaceAll(shareText, '&', '%26');
        shareText = this.replaceAll(shareText, '@', '%%40');
        shareText = this.replaceAll(shareText, '/', '%2F');
        shareText = this.replaceAll(shareText, '#', '%23');
        shareText = this.replaceAll(shareText, ':', '%3A');

        const phoneNumber = this.replaceAll(this.business.phoneNumber, '+', '%2B');

        this.saveClientInfo();
        window.location = "https://wa.me/" + phoneNumber + "?text=" + shareText;

      } catch (e) {
        console.log(e);
        await this.$router.push({name: 'errorInternal'});
      } finally {
        this.creatingOrderLoading = false;
      }
    },

    /**
     * Save the client information in the local storage for use later.
     */
    saveClientInfo: function () {
      try {
        const miStorage = window.localStorage
        miStorage.setItem('seoferta', JSON.stringify({
          name: this.clientData.name,
          address: this.clientData.address
        }))
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * Load the client information from the local storage.
     */
    loadClientInfo: function () {
      try {
        const savedClientData = JSON.parse(window.localStorage.getItem('seoferta') || '{}');

        if (savedClientData.name) {
          this.clientData.name = savedClientData.name
        }
        if (savedClientData.address) {
          this.clientData.address = savedClientData.address;
        }
      } catch (e) {
        console.log(e)
      }
    },

    /**
     *
     */
    replaceAll: function (string, search, replace) {
      return string.split(search).join(replace)
    },

    /**
     *
     * @returns {Promise<void>}
     */
    reportBusinessVisitor: async function () {
      if (!this.$cookies.isKey(this.business.slug)) {
        this.$cookies.set(this.business.slug, {
          products: []
        }, 60 * 60); // 1 hour
        await services.statistic.reportBusinessVisit(this.business.slug)
      }
    },

    /**
     *
     * @param productId
     * @returns {Promise<void>}
     */
    reportProductVisualization: async function (productId) {
      if (this.$cookies.isKey(this.business.slug)) {
        let products = this.$cookies.get(this.business.slug).products
        if (products.indexOf(productId) < 0) {
          products.push(productId)
          this.$cookies.set(this.business.slug, {
            products: products
          }, 60 * 60); // 1 hour
          await services.statistic.reportProductVisit(productId);
        }
      }
    },

    /**
     *
     */
    getFirstCharacter: getFirstChar,
  },
  computed: {

    /**
     *
     * @returns {number}
     */
    priceCUP: function () {
      let totalPrice = 0;
      for (let i = 0; i < this.myCar.products.cup.length; i++) {
        totalPrice += this.myCar.products.cup[i].item.price * this.myCar.products.cup[i].count;
      }
      return totalPrice;
    },

    /**
     *
     * @returns {number}
     */
    priceUSD: function () {
      let totalPrice = 0;
      for (let i = 0; i < this.myCar.products.usd.length; i++) {
        totalPrice += this.myCar.products.usd[i].item.price * this.myCar.products.usd[i].count;
      }
      return totalPrice;
    },

    /**
     *
     * @returns {number}
     */
    totalProductInCard: function () {
      let result = 0;
      for (let i = 0; i < this.myCar.products.usd.length; i++) {
        result += this.myCar.products.usd[i].count;
      }
      for (let i = 0; i < this.myCar.products.cup.length; i++) {
        result += this.myCar.products.cup[i].count;
      }
      return result;
    },

    /**
     *
     * @returns {boolean}
     */
    fullscreen: function () {
      return window.screen.width < 768;
    },

    /**
     *
     * @returns {*}
     */
    isLogged() {
      return this.$store.state.auth.loggedIn;
    },
  },
}
</script>

<style scoped>

.zoomInZoomOut {
  animation: zoomInZoomOut 0.5s ease-out;
}

@keyframes zoomInZoomOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.title-scroll-margin {
  scroll-margin: 65px 0 0 0;
}

.about {
  padding: 5px 20px;
}

.v-chip-text {
  font-weight: 500;
}

.theme--light.v-chip.chip:not(.v-chip--active) {
  background: #ebf5f5 !important;
}

.v-text-field--outlined.v-input--dense.v-text-field--outlined
> .v-input__control
> .v-input__slot {
  min-height: 30px !important;
}

.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 30px !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 15px !important;
}

.custom-transform-class {
  text-transform: uppercase;
}

.v-chip-group .v-slide-group__next,
.v-slide-group__prev {
  flex: 0 1 0px !important;
  min-width: 3px !important;
}

.my-chip-center {
  display: inline-table !important;
}


</style>
<style>
html {
  scroll-behavior: smooth;
}

.v-image__image--preload {
  filter: blur(0.1px) !important;
}

.v-slide-group__next, .v-slide-group__prev {
  flex: 0 1 52px;
  min-width: 0px;
}
</style>

