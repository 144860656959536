<template>
  <div>
    <v-container>
      <v-card class="mx-auto my-2" max-width="500" outlined>
        <!-- Business -->
        <v-card-text class="text-center pt-6 pb-0">
          <v-list-item-avatar v-if="!invoice.business.logo"
                              color="white" size="90" style="border: 2px  solid rgba(0, 0, 0, 0.12) !important;">
            <v-icon disabled large>
              mdi-tag-multiple-outline
            </v-icon>
          </v-list-item-avatar>
          <v-avatar v-else size="65">
            <img :alt="invoice.business.name"
                 :src="invoice.business.logo"/>
          </v-avatar>
          <h2 class="mt-3 mb-1 font-weight-medium" style="color: black">
            {{ invoice.business.name }}
          </h2>
          <p> {{ invoice.business.address }}</p>
        </v-card-text>
        <v-card-text style="color: black">
          <div class="d-flex justify-space-between">
            <div class="font-weight-medium">Número Factura</div>
            <div class="font-weight-medium"> {{ invoice.order_number }}</div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="font-weight-medium">Fecha Operación</div>
            <div> {{ invoice.created }}</div>
          </div>
        </v-card-text>

        <!-- Date Client -->
        <v-card-text class="pt-1" style="color: black">
          <div class="d-flex justify-space-between">
            <div class="font-weight-medium">Cliente</div>
            <div>{{ invoice.client_name }}</div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="font-weight-medium">Número Teléfono</div>
            <div>-</div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="font-weight-medium">Dirección</div>
            <div>{{ invoice.client_address }}</div>
          </div>
        </v-card-text>

        <!-- Shopping Cart-->
        <v-card-text class="pb-0">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Cant.
                </th>
                <th class="text-left">
                  Precio
                </th>
                <th class="text-left">
                  Producto
                </th>
                <th class="text-right">
                  subTotal
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(product, index) in invoice.products"
                  :key="index">
                <td> {{ product.amount }}</td>
                <td> {{ product.price + ' ' + product.currency }}</td>
                <td> {{ product.name }}</td>
                <td class="text-right"> {{ product.price * product.amount }} <small>{{ product.currency }}</small></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text class="pt-1">
          <v-divider style="border: 1px solid"></v-divider>
          <div v-for="total in invoice.totals" class="pt-3 d-flex">
            <div class="ml-4 mr-auto font-weight-medium" style="color: black">TOTAL</div>
            <div class="mr-4 font-weight-medium" style="color: black">{{ total.value }}</div>
            <div class="font-weight-medium mr-4">{{ total.currency }}</div>
          </div>
          <div v-if="invoice.home_delivery" class="pt-1 d-flex">
            <div class="ml-4 mr-auto font-weight-medium" style="color: black">Servicio a Domicilio</div>
            <div class="mr-4 font-weight-medium" style="color: black">{{ invoice.home_delivery_price }}</div>
            <div class="font-weight-medium mr-4">CUP</div>
          </div>
        </v-card-text>

        <!-- Note se muestra si hay contenido -->
        <v-card-subtitle v-if="invoice.client_note" class="mx-4 mb-3" style="color: black">
          <strong>NOTA -</strong> {{ invoice.client_note }}
        </v-card-subtitle>

        <!-- Footer -->
        <v-card-text class="text-center">
          <p>¡Gracias!</p>
        </v-card-text>

      </v-card>
    </v-container>
  </div>
</template>

<script>
import services from "../services"

export default {
  name: "Invoice",
  data: () => ({
    invoice: {
      order_number: '***',
      created: '***',
      client_name: '***',
      client_address: '***',
      home_delivery: false,
      home_delivery_price: null,
      client_note: false,
      business: {
        name: '***',
        address: '***',
        logo: false
      }
    },
  }),
  mounted: function () {
    const orderId = this.$route.params.invoice
    const businessSlug = this.$route.params.slug
    services.orders.getOrder(businessSlug, orderId)
        .then((result) => {
          this.invoice = result.data
          this.invoice.business.logo = result.data.business.logo.path
        })
        .catch(() => {
          this.$router.push({name: 'errorInternal'});
        })
  },
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white;
}
</style>
